import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InquiryQnA } from 'src/app/models/inquiryQnA';
import { NgHttpCachingService } from 'ng-http-caching';

@Injectable({
  providedIn: 'root',
})
export class QnAService {
  private http = inject(HttpClient);
  private header = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-NG-HTTP-CACHING-ALLOW-CACHE': 'true',
  });

  private ngHttpCachingService = inject(NgHttpCachingService);

  getAdminQnAs(): Observable<InquiryQnA[]> {
    return this.http.get<InquiryQnA[]>(
      environment.matchMakingApiUrl + '/v1/admin/inquiryqnas',
      { headers: this.header }
    );
  }

  getadminListQnAsByInquiryId(inquiryId: string): Observable<InquiryQnA[]> {
    this.ngHttpCachingService.clearCacheByRegex(/inquiryqnas/);
    return this.http.get<InquiryQnA[]>(
      environment.matchMakingApiUrl + `/v1/admin/inquiryqnas/i/${inquiryId}`,
      { headers: this.header }
    );
  }

  adminUpdateQnA(inquiryQnA: InquiryQnA): Observable<InquiryQnA> {
    return this.http.put<InquiryQnA>(
      environment.matchMakingApiUrl +
        `/v1/admin/inquiryqnas/q/${inquiryQnA.id}/edit`,
      inquiryQnA,
      { headers: this.header }
    );
  }

  verifyQnA(inquiryQnA: InquiryQnA): Observable<InquiryQnA> {
    return this.http.put<InquiryQnA>(
      environment.matchMakingApiUrl +
        `/v1/admin/inquiryqnas/q/${inquiryQnA.id}/verify`,
      inquiryQnA,
      { headers: this.header }
    );
  }

  removeAdminQnAById(qnaId: string): Observable<InquiryQnA> {
    return this.http.delete<InquiryQnA>(
      environment.matchMakingApiUrl + `/v1/admin/inquiryqnas/q/${qnaId}`,
      { headers: this.header }
    );
  }

  removeQnAById(qnaId: string): Observable<InquiryQnA> {
    return this.http.delete<InquiryQnA>(
      environment.matchMakingApiUrl + `/v1/inquiryqnas/q/${qnaId}`,
      { headers: this.header }
    );
  }

  getListQnAsByInquiryId(inquiryId: string): Observable<InquiryQnA[]> {
    return this.http.get<InquiryQnA[]>(
      environment.matchMakingApiUrl + `/v1/inquiryqnas/i/${inquiryId}`,
      { headers: this.header }
    );
  }

  createQnAs(inquiryQnAs: InquiryQnA[]): Observable<InquiryQnA[]> {
    return this.http.post<InquiryQnA[]>(
      `${environment.matchMakingApiUrl}/v1/inquiryqnas`,
      inquiryQnAs,
      { headers: this.header }
    );
  }

  updateQnA(inquiryQnA: InquiryQnA): Observable<InquiryQnA> {
    return this.http.put<InquiryQnA>(
      environment.matchMakingApiUrl + `/v1/inquiryqnas`,
      inquiryQnA,
      { headers: this.header }
    );
  }

  updateQnAQuestion(inquiryQnA: InquiryQnA): Observable<InquiryQnA> {
    return this.http.put<InquiryQnA>(
      environment.matchMakingApiUrl + `/v1/inquiryqnas/q`,
      inquiryQnA,
      { headers: this.header }
    );
  }

  updateQnAAnswer(inquiryQnA: InquiryQnA): Observable<InquiryQnA> {
    return this.http.put<InquiryQnA>(
      environment.matchMakingApiUrl + `/v1/inquiryqnas/a`,
      inquiryQnA,
      { headers: this.header }
    );
  }
}
